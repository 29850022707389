function getDeviceInfo() {
  const userAgent = navigator.userAgent;
  let device, os, resolution;

  // Detect device
  if (/mobi/i.test(userAgent)) {
    device = 'Mobile';
  } else {
    device = 'Desktop';
  }

  // Detect operating system
  if (/Android/i.test(userAgent)) {
    os = 'Android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    os = 'iOS';
  } else if (/Mac/i.test(userAgent)) {
    os = 'MacOS';
  } else if (/Win/i.test(userAgent)) {
    os = 'Windows';
  } else if (/Linux/i.test(userAgent)) {
    os = 'Linux';
  } else {
    os = 'Unknown';
  }

  resolution = `${window.screen.width}x${window.screen.height}`;

  return window.btoa(
    JSON.stringify({
      device: device,
      os: os,
      resolution: resolution,
    })
  );
}

export function track(message: string, event: object) {
  const device = getDeviceInfo();
  const data = { channel: 'LANDING', message, context: { event, device } };
  const blob = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });

  navigator.sendBeacon(`${process.env.NEXT_PUBLIC_API_URL}/api/metrics`, blob);
}
