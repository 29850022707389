/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';

import Instagram from '../../icons/fa/brands/instagram';
import Twitter from '../../icons/fa/brands/twitter';
import Discord from '../../icons/fa/brands/discord';
import Facebook from '../../icons/fa/brands/facebook-f';

import style from './footer.module.scss';
import { track } from '@/track';

export function Footer() {
  return (
    <footer className={style.root}>
      <div>
        <div className={style.logo}>
          <img src="/logo-white.svg" alt="ImaWakatta - The best way to learn Japanese" width={520} height={63} />
        </div>
        <ul className={style.list}>
          <li>
            <Link href="/about" className={style.link}>
              About
            </Link>
          </li>
          <li>
            <Link href="/blog" className={style.link}>
              Blog
            </Link>
          </li>
          <li>
            <Link href="/terms" className={style.link}>
              Terms & Conditions
            </Link>
          </li>
          <li>
            <Link href="/privacy" className={style.link} aria-label="View our privacy policy">
              Privacy Policy
            </Link>
          </li>
        </ul>
        <ul className={style.socials}>
          <li>
            <a
              aria-label="Visit us on Instagram"
              href="https://www.instagram.com/imawakatta"
              className={style.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => track('footer-socials-click', { type: 'Instagram' })}
            >
              <Instagram className={style.instagram} />
            </a>
          </li>
          <li>
            <a
              aria-label="Join us on Discord"
              href="https://discord.gg/8hw73Zt6Az"
              className={style.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => track('footer-socials-click', { type: 'Discord' })}
            >
              <Discord className={style.twitter} />
            </a>
          </li>
          <li>
            <a
              aria-label="Visit us on Twitter"
              href="https://twitter.com/imawakattaapp"
              className={style.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => track('footer-socials-click', { type: 'Twitter' })}
            >
              <Twitter className={style.twitter} />
            </a>
          </li>
          <li>
            <a
              aria-label="Visit us on Facebook"
              href="https://www.facebook.com/ImaWakatta"
              className={style.link}
              target="_blank"
              rel="noreferrer"
              onClick={() => track('footer-socials-click', { type: 'Facebook' })}
            >
              <Facebook className={style.facebook} />
            </a>
          </li>
        </ul>
        <div className={style.copyright}>
          &copy; {new Date().getFullYear()}&nbsp;
          <a href="https://workinggears.com" target="_blank" rel="noreferrer">
            Working Gears Pty Ltd
          </a>
        </div>
      </div>
      <div className={style.circle} />
    </footer>
  );
}
