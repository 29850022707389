/* eslint-disable @next/next/no-img-element */
import React, { useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import Bars from '../../icons/fa/solid/bars';
import style from './nav.module.scss';
import { useRouter } from 'next/router';
import { track } from '@/track';

interface NavProps {
  chromeless?: boolean;
}

export const Nav = (props: NavProps) => {
  const { chromeless = false } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useRouter();

  return (
    <div
      className={classNames(style.root, {
        [style.mobileMenuOpen]: mobileMenuOpen,
      })}
    >
      <div className={style.wrapper}>
        <Link href="/" className={style.bubbleWrapper}>
          <img src="/logo.svg" className={style.logo} alt="ImaWakatta" />
          <div className={style.bubble}>&ldquo;Now I understand!&rdquo;</div>
        </Link>
        {!chromeless && (
          <button className={style.hamburger} onClick={() => setMobileMenuOpen(!mobileMenuOpen)} aria-label="Menu">
            <Bars />
          </button>
        )}
        <ul className={style.list}>
          <li className={style.item}>
            <Link
              href="/"
              className={classNames(style.link, { [style.active]: pathname === '/' })}
              onClick={() => track('nav-click', { link: 'Home' })}
            >
              Home
            </Link>
          </li>
          <li className={style.item}>
            <Link
              href="/about"
              className={classNames(style.link, { [style.active]: pathname === '/about' })}
              onClick={() => track('nav-click', { link: 'About' })}
            >
              About
            </Link>
          </li>
          <li className={style.item}>
            <Link href="/#pricing" className={style.link} onClick={() => track('nav-click', { link: 'Pricing' })}>
              Pricing
            </Link>
          </li>
          <li className={style.item}>
            <a
              href="https://discord.gg/8hw73Zt6Az"
              className={style.link}
              onClick={() => track('nav-click', { link: 'Discord' })}
            >
              Discord
            </a>
          </li>
          <li className={style.item}>
            <Link
              href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
              className={style.button}
              onClick={() => track('nav-click', { link: 'Login' })}
            >
              Login
            </Link>
          </li>
          <li className={style.item}>
            <Link
              href={`${process.env.NEXT_PUBLIC_APP_URL}/register`}
              className={classNames(style.button, style.primary)}
              onClick={() => track('register', { from: 'nav' })}
            >
              Register
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
