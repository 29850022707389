import React, { ReactNode } from 'react';
import Head from 'next/head';
import { useSearchParams } from 'next/navigation';

import { Nav } from '../../components/nav/nav';
import { Footer } from '../../components/footer/footer';

import style from './landing-layout.module.scss';

type LandingLayout = {
  children: ReactNode;
  title?: string;
};

export function LandingLayout(props: LandingLayout) {
  const { children, title = 'ImaWakatta' } = props;
  const { get } = useSearchParams();

  const chromeless = get('chromeless') === 'true';

  return (
    <div className={style.root}>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:site_name" content="ImaWakatta" />
        <meta
          name="description"
          content="Hundreds of lessons, interactions, quizzes, resources and audio. The best way to learn Japanese - ImaWakatta."
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <Nav chromeless={chromeless} />
      {children}
      {!chromeless && <Footer />}
    </div>
  );
}
